<template>
  <div>
      <el-form class="add-payway-form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="三方" prop="new_type">
              <el-select v-model="newType" placeholder="请选择通道" :disabled="true">
                  <el-option :label="newTypeName" :value="newType" ></el-option>
              </el-select>
          </el-form-item>
          <el-form-item :label="item.label" :prop="item.name" v-for="(item,index) in formData" :key="index">
             
                  <template v-if="item.type == 'text'">
                      <el-input v-if="item.name !='callback_ip'" v-model="ruleForm[item.name]" :placeholder="`请输入${item.label}`"></el-input>
                      <div v-if="item.name == 'callback_ip'">
                          <template v-if="callBackIp.ipTags.length>0">
                              <el-tag v-for="tag in callBackIp.ipTags"
                                  type="success"
                                  class="mr10"
                                  :key="tag"                              
                                  closable
                                  :disable-transitions="false"
                                  @close="taghandleClose(tag)">
                                  {{tag}}
                              </el-tag>
                          </template>
                          <el-input
                              class="input-new-tag mr10"
                              v-if="callBackIp.inputVisible"
                              v-model="callBackIp.inputValue"
                              ref="saveTagInput"
                              size="mini"
                              @keyup.enter.native="taghandleInputConfirm"
                              @blur="taghandleInputConfirm">
                          </el-input>
                          <el-button v-else class="button-new-tag" size="small" @click="tagshowInput">+</el-button>
                      </div>
                  </template>
                  <template v-if="item.type == 'number'">
                      <el-input v-model="ruleForm[item.name]" :placeholder="`请输入${item.label}`"></el-input>
                  </template>
                  <template v-if="item.type == 'textarea'">
                      <el-input type="textarea"  :rows="item.name =='own_private_key'?8:3" v-model="ruleForm[item.name]" :placeholder="`请输入${item.label}`"></el-input>
                  </template>
          </el-form-item>
      </el-form> 
      <div class="footer-button-box">
          <el-button @click="editCancel()">{{$t('main.common.cancel')}}</el-button>
    <el-button type="primary" @click='editSure()'>{{$t('main.common.submit')}}</el-button>
      </div>
  </div>
</template>
<script>
  export default {
      name: 'passagaAddComponents',
      props:{
        editData:{//渲染数据
          type:Array,
          required: true,
        },
        formObj:{//参数及val
          type:Object,
          required: true,
        },
        newType:{
          type:String,
        },
        newTypeName:{
          type:String,
        }
       
      },
      
    created() {
      this.ruleForm={...this.formObj}
      this.formData = this.editData
      this.callBackIp.ipTags = this.ruleForm.callback_ip ==''?[] :this.ruleForm.callback_ip.split('|')
    },

    data() {
      return {
          loading:false,
          ruleForm: {
              new_type:'',
          },
          rules: {
          // name: [
          //   { required: true, message: '请输入活动名称', trigger: 'blur' },
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          // ],
          // region: [
          //   { required: true, message: '请选择活动区域', trigger: 'change' }
          // ],
          // date1: [
          //   { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          // ],
          // date2: [
          //   { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
          // ],
          // type: [
          //   { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
          // ],
          // resource: [
          //   { required: true, message: '请选择活动资源', trigger: 'change' }
          // ],
          // desc: [
          //   { required: true, message: '请填写活动形式', trigger: 'blur' }
          // ]
          },
          formData:[],
          //单独处理回调IP
          callBackIp:{
              inputVisible:false,//ip编辑框是否显示，
              ipTags:[],//回调ip标签数组
              inputValue:'',//回调IP编辑框值
          }
        
      };
    },
    methods: {
      // submitForm(formName) {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       alert('submit!');
      //     } else {
      //       console.log('error submit!!');
      //       return false;
      //     }
      //   });
      // },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // },
     
      
      editSure(){
          if(this.ruleForm){
              Object.keys(this.ruleForm).forEach(key => {
                  if(key == 'callback_ip'){
                    if(this.callBackIp.ipTags !=''){
                      this.ruleForm.callback_ip = this.callBackIp.ipTags.join('|')
                    }else{
                        this.ruleForm.callback_ip= ''
                    }
                      
                  }
                  // console.log(key, this.ruleForm[key]);
              });
          }
          this.loading = true
          this.$api.editPayway(this.ruleForm).then(res =>{
              this.loading = false
              if(res.status == 1){
                  this.$emit('editCancel')
                  this.$emit('updateDataList')
                  this.$message({
                      type: 'success',
                      message: res.msg
                  })
              }else{
                  this.$message({
                      type: 'error',
                      message: res.msg
                  })
              }
          }).catch(err=>{
              this.loading = false;
          })
         
      },
      //回调IP编辑框显示按钮        
      tagshowInput() {
          this.callBackIp.inputVisible = true;
          this.$nextTick(_ => {
              this.$refs.saveTagInput.$refs.input.focus();
          });
      },
      //本地提交ip标签
      taghandleInputConfirm() {
          let inputValue = this.callBackIp.inputValue;
          if (inputValue) {
              this.callBackIp.ipTags.push(inputValue);
          }
          this.callBackIp.inputVisible = false;
          this.callBackIp.inputValue = '';
      },
      //本地删除ip标签
      taghandleClose(tag) {
          this.callBackIp.ipTags.splice(this.callBackIp.ipTags.indexOf(tag), 1);
      },
      //取消新增
      editCancel(){
          this.$emit('editCancel')
          // this.resetForm();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .add-payway-form{
      min-height: 200px;
  }
  .footer-button-box{
      text-align: right;
      margin-top: 30px;
  }
</style>