import { render, staticRenderFns } from "./passagaAddComponents.vue?vue&type=template&id=13fa50a9&scoped=true&"
import script from "./passagaAddComponents.vue?vue&type=script&lang=js&"
export * from "./passagaAddComponents.vue?vue&type=script&lang=js&"
import style0 from "./passagaAddComponents.vue?vue&type=style&index=0&id=13fa50a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13fa50a9",
  null
  
)

export default component.exports