<template>
    <div>
        <el-form class="add-payway-form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="三方" prop="new_type">
                <el-select v-model="ruleForm.new_type" placeholder="请选择通道" @change="passageChange">
                    <el-option :label="item.label" :value="item.name" v-for="(item,index) in addData" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="item.label" :prop="item.name" v-for="(item,index) in formData" :key="index">
               
                    <template v-if="item.type == 'text'">
                        <el-input v-if="item.name !='callback_ip'" v-model="ruleForm[item.name]" :placeholder="`请输入${item.label}`"></el-input>
                        <div v-if="item.name == 'callback_ip'">
                            <template v-if="callBackIp.ipTags.length>0">
                                <el-tag v-for="tag in callBackIp.ipTags"
                                    type="success"
                                    class="mr10"
                                    :key="tag"                              
                                    closable
                                    :disable-transitions="false"
                                    @close="taghandleClose(tag)">
                                    {{tag}}
                                </el-tag>
                            </template>
                            <el-input
                                class="input-new-tag mr10"
                                v-if="callBackIp.inputVisible"
                                v-model="callBackIp.inputValue"
                                ref="saveTagInput"
                                size="mini"
                                @keyup.enter.native="taghandleInputConfirm"
                                @blur="taghandleInputConfirm">
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="tagshowInput">+</el-button>
                        </div>
                    </template>
                    <template v-if="item.type == 'number'">
                        <el-input v-model="ruleForm[item.name]" :placeholder="`请输入${item.label}`"></el-input>
                    </template>
                    <template v-if="item.type == 'textarea'">
                        <el-input type="textarea"  :rows="item.name =='own_private_key'?8:3" v-model="ruleForm[item.name]" :placeholder="`请输入${item.label}`"></el-input>
                    </template>
            </el-form-item>
            <!-- <el-form-item label="活动名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="活动区域" prop="region">
            <el-select v-model="ruleForm.region" placeholder="请选择活动区域">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="活动时间" required>
            <el-col :span="11">
                <el-form-item prop="date1">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
                <el-form-item prop="date2">
                <el-time-picker placeholder="选择时间" v-model="ruleForm.date2" style="width: 100%;"></el-time-picker>
                </el-form-item>
            </el-col>
            </el-form-item>
            <el-form-item label="即时配送" prop="delivery">
            <el-switch v-model="ruleForm.delivery"></el-switch>
            </el-form-item>
            <el-form-item label="活动性质" prop="type">
            <el-checkbox-group v-model="ruleForm.type">
                <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                <el-checkbox label="地推活动" name="type"></el-checkbox>
                <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
            </el-checkbox-group>
            </el-form-item>
            <el-form-item label="特殊资源" prop="resource">
            <el-radio-group v-model="ruleForm.resource">
                <el-radio label="线上品牌商赞助"></el-radio>
                <el-radio label="线下场地免费"></el-radio>
            </el-radio-group>
            </el-form-item>
            <el-form-item label="活动形式" prop="desc">
            <el-input type="textarea" v-model="ruleForm.desc"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item> -->
        </el-form> 
        <div class="footer-button-box">
            <el-button @click="addCancel()" :disabled="loading">{{$t('main.common.cancel')}}</el-button>
			<el-button type="primary" @click='addSure()' :disabled="loading">{{$t('main.common.submit')}}</el-button>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'passagaAddComponents',
        props:{
            addData:{
                type:Object,
                required: true,
            }
        },
        
      created() {
        // console.log('zujiann', this.addData)
      },

      data() {
        return {
            loading:false,
            ruleForm: {
                new_type:'',
            },
            rules: {
            // name: [
            //   { required: true, message: '请输入活动名称', trigger: 'blur' },
            //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
            // ],
            // region: [
            //   { required: true, message: '请选择活动区域', trigger: 'change' }
            // ],
            // date1: [
            //   { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
            // ],
            // date2: [
            //   { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
            // ],
            // type: [
            //   { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
            // ],
            // resource: [
            //   { required: true, message: '请选择活动资源', trigger: 'change' }
            // ],
            // desc: [
            //   { required: true, message: '请填写活动形式', trigger: 'blur' }
            // ]
            },
            formData:[],
            //单独处理回调IP
            callBackIp:{
                inputVisible:false,//ip编辑框是否显示，
                ipTags:[],//回调ip标签数组
                inputValue:'',//回调IP编辑框值
            }
          
        };
      },
      methods: {
        // submitForm(formName) {
        //   this.$refs[formName].validate((valid) => {
        //     if (valid) {
        //       alert('submit!');
        //     } else {
        //       console.log('error submit!!');
        //       return false;
        //     }
        //   });
        // },
        // resetForm(formName) {
        //   this.$refs[formName].resetFields();
        // },
        passageChange(val){
            this.formData = this.addData[this.ruleForm.new_type].fields
            let formKey={}
            formKey.new_type = val
            this.formData.forEach(item => {
                // console.log(item.name,'====key:',item.type)               
                if(item.type == 'number'){
                    formKey[item.name] = 0
                }else{
                    formKey[item.name] = ''
                }
                
            });
            this.ruleForm ={...formKey}
            // console.log(this.ruleForm,this.formData )

        },
        resetForm(){
            //重置表单数据
            this.ruleForm = {
                new_type:''
            }
            this.formData = []
            this.callBackIp={
                inputVisible:false,//ip编辑框是否显示，
                ipTags:[],//回调ip标签数组
                inputValue:'',//回调IP编辑框值
            }
        },
        addSure(){
            if(this.ruleForm){
                Object.keys(this.ruleForm).forEach(key => {
                    if(key == 'callback_ip'){
                        if(this.callBackIp.ipTags !=''){
                            this.ruleForm.callback_ip = this.callBackIp.ipTags.join('|')
                        }else{
                            this.ruleForm.callback_ip= ''
                        }
                        
                    }
                    // console.log(key, this.ruleForm[key]);
                });
            }
            this.loading = true
            this.$api.addPayway(this.ruleForm).then(res =>{
                this.loading = false
                if(res.status == 1){
                    this.$emit('addCancel')
                    this.$emit('updateDataList')
                    this.resetForm();
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }
            }).catch(err=>{
                this.loading = false;
            })
           
        },
        //回调IP编辑框显示按钮        
        tagshowInput() {
            this.callBackIp.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        //本地提交ip标签
        taghandleInputConfirm() {
            let inputValue = this.callBackIp.inputValue;
            if (inputValue) {
                this.callBackIp.ipTags.push(inputValue);
            }
            this.callBackIp.inputVisible = false;
            this.callBackIp.inputValue = '';
        },
        //本地删除ip标签
        taghandleClose(tag) {
            this.callBackIp.ipTags.splice(this.callBackIp.ipTags.indexOf(tag), 1);
        },
        //取消新增
        addCancel(){
            this.$emit('addCancel')
            this.resetForm();
        }
      }
    }
</script>
<style lang="scss" scoped>
    .add-payway-form{
        min-height: 200px;
    }
    .footer-button-box{
        text-align: right;
        margin-top: 30px;
    }
</style>